/*===================================================
    Responsive Css
=====================================================*/

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.web-sh.navbar-form {
		width: 65%;
	}

	.how-text-left {
		font-size: 168px;
	}

	.carousel_mobile {
		display: none;
	}



}

@media only screen and (min-width: 992px) and (max-width: 1090px) {

	/* .help-r {
	display: none;
} */
	.carousel_mobile {
		display: none;
	}

	.nav-box {
		padding: 0px 12px;
	}

	.pricing-list>li {
		width: 33.33%;
	}

	.pricing-list>li:last-child {
		width: 100%;
	}

	.left-profile-box-m h3 {
		font-size: 13px;
	}

	.small-box-c .prod-btn a {
		margin-right: 0px;
	}

	.web-sh.navbar-form {
		width: 65%;
	}
}

/*===========================================
	Tab responsive
=============================================*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.text_title {
		font-size: 2em;
		padding: 10px;
		text-transform: uppercase;
		/* background-image: url('../images/banner_top_kafood.png'); */
		text-align: center;
	}

	.carousel_mobile {
		display: none;
	}

	header {
		z-index: inherit;
	}

	.hidden-sm {
		display: none !important;
	}

	.left-rs {
		padding: 0px;
	}

	.web-sh.navbar-form {
		width: 100%;
	}

	.navbar-header {
		float: none;
	}

	.navbar-toggler {
		display: block;
	}

	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
		display: flex;
		background-color: none !important;
	}

	/* .navbar-collapse.collapse {
	display: none!important;
} */
	.navbar-nav {
		float: none !important;
		margin: 7.5px -15px;
	}

	.navbar-nav>li {
		float: none;
	}

	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
		color: #000 !important;
	}

	.navbar-collapse.collapse.in {
		/* NEW */
		display: block !important;
		background: #000;
	}

	/* .right-nav {
	display: none;
} */
	.navbar>.container .navbar-brand,
	.navbar>.container-fluid .navbar-brand {
		margin-left: 0px;
	}

	header .navbar-default .navbar-header {
		border-bottom: 1px solid #e8e8e8;
	}

	.f_pd h4 {
		padding-right: 0px;
	}

	.f_pd p {
		padding-right: 0px;
	}

	.l_pd {
		padding-left: 10px;
	}

	.l_pd h4 {
		padding-right: 0px;
	}

	.start-free-box {
		padding: 20px 0px;
	}

	.main-start-box {
		padding: 0px 10px;
	}

	.right-buyer {
		padding-left: 0px;
	}

	.navbar-default .navbar-toggler .icon-bar {
		background-color: #09294c;
	}

	.navbar-default .navbar-toggler:focus,
	.navbar-default .navbar-toggler:hover {
		background-color: transparent;
	}

	.navbar-default .navbar-toggler {
		border: none;
		margin: 16px 25px;
	}

	.top-head .navbar-toggler .icon-bar:nth-of-type(3) {
		top: 1px;
	}

	.top-head .navbar-toggler .icon-bar:nth-of-type(4) {
		top: 2px;
	}

	.top-head .navbar-toggler .icon-bar {
		position: relative;
		transition: all 250ms ease-in-out;
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(2) {
		top: 6.5px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(3) {
		background-color: transparent;
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(4) {
		top: -5px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
	}

	.top-head .navbar-toggler .icon-bar {
		position: relative;
		transition: all 250ms ease-in-out;
		-webkit-transition: all 250ms ease-in-out;
		-moz-transition: all 250ms ease-in-out;
		-ms-transition: all 250ms ease-in-out;
	}

	.fixed-menu .web-sh.navbar-form {
		display: none;
	}

	.fixed-menu {
		z-index: 1023;
	}

	.copyright p {
		text-align: center;
	}

	.copyright .socials {
		float: none;
		margin: 0 auto;
		text-align: center;
	}

	.how-text-left {
		width: 100%;
		padding-top: 40px;
	}

	.how-dit-right {
		width: 100%;
	}

	.section-white .img-description {
		margin-bottom: 30px;
	}

	.prod-page .pro-img {
		display: inline-block;
	}

	.price-box-right {
		width: 60%;
		margin: 0 auto;
	}

	.head-add {
		z-index: 1023;
	}

	.left-profile-box-m .pro-img {
		display: inline-block;
	}

	.profile-pro-left {
		margin-top: -20px;
	}

	.right-flag {
		display: inline-block;
		float: none;
	}

	.copyright .socials {
		display: inline-block;
	}

	.copyright {
		text-align: center;
	}

	.hidden-xs {
		display: none;
	}

	.pricing-list>li {
		width: 33.33%;
	}

	.pricing-list>li:last-child {
		width: 100%;
	}
}

/*=========================================
    SMart Phone Device
===========================================*/

@media only screen and (min-width: 300px) and (max-width: 767px) {
	body {
		font-size: 14px !important;
	}

	.nav-box ul {
		padding: 0px !important;
	}

	.left-supplier h1 {
		font-size: 1.7rem;
	}

	.function {
	
	}

	.carousel_mobile {
		display: none;
	}

	.button-28 {
		/* min-width: 10rem; */
	}

	.carousel_mobile {
		display: block;
	}

	.tableCost tr,
	td,
	th {
		/* border: #000 10px 10px 10px 10px; */
		border-width: 1px !important;
		font-size: 1rem;

	}

	td {
		color: #000 !important;
	}

	.carousel_ {
		display: none;
	}

	/* .carousel_mobile{
		
	} */

	.fixed-menu .web-sh.navbar-form {
		display: none;
	}

	.fixed-menu .right-nav {
		display: none;
	}

	.fixed-menu {
		z-index: 1023;
	}

	header {
		z-index: inherit;
	}

	.head-add .navbar-header {
		z-index: 1023;
	}

	.web-sh.navbar-form {
		width: 100%;
		padding: 8px 15px;
	}

	.left-rs {
		padding: 0px;
	}

	header .navbar-default .navbar-header {
		border-bottom: 1px solid #e8e8e8;
	}

	.login-signup {
		padding: 5px 0px;
	}

	.navbar-default .navbar-toggler:focus,
	.navbar-default .navbar-toggler:hover {
		background-color: transparent;
	}



	.navbar-default .navbar-toggler .icon-bar {
		background-color: #09294c;
	}

	.top-head .navbar-toggler .icon-bar:nth-of-type(3) {
		top: 1px;
	}

	.top-head .navbar-toggler .icon-bar:nth-of-type(4) {
		top: 2px;
	}

	.top-head .navbar-toggler .icon-bar {
		position: relative;
		transition: all 250ms ease-in-out;
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(2) {
		top: 6.5px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(3) {
		background-color: transparent;
	}

	.top-head .navbar-toggler.active .icon-bar:nth-of-type(4) {
		top: -5px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
	}

	.top-head .navbar-toggler .icon-bar {
		position: relative;
		transition: all 250ms ease-in-out;
		-webkit-transition: all 250ms ease-in-out;
		-moz-transition: all 250ms ease-in-out;
		-ms-transition: all 250ms ease-in-out;
	}

	.menu-opened .responsive-menu-btn i b {
		background: transparent
	}

	.form-sh {
		margin-bottom: 15px;
	}

	.find-box {
		padding: 60px 0px;
	}

	.cat-main-box .panel {
		margin-bottom: 100px;
	}

	.panel-row {
		margin-bottom: 20px;
	}

	.f_pd h4 {
		padding-right: 0px;
	}

	.f_pd p {
		padding-right: 0px;
	}

	.l_pd {
		padding-left: 0px;
	}

	.l_pd h4 {
		padding-right: 0px;
	}

	.start-free-box {
		/* padding: 20px 0px; */
		margin-left: -3rem;
	}

	.main-start-box {
		padding: 0px 10px;
	}

	.right-buyer {
		padding-left: 0px;
	}

	.right-buyer h2 {
		font-size: 26px;
	}

	.left-supplier {
		padding-right: 0px;
		margin-bottom: 20px;
		padding-left: 0;
	}

	.prod-box {
		margin: 0px 3px;
	}

	.footer-top h2 {
		padding-bottom: 12px;
	}

	.form-box {
		margin-right: 0px;
		margin-bottom: 12px;
	}

	.help-box-f h4 {
		font-size: 1rem;
	}

	.help-box-f p {
		font-size: 11px;
	}

	.footer-top {
		padding-bottom: 10px;
	}

	.footer-link-box {
		padding: 30px 0px;
	}

	.copyright p {
		text-align: center;
	}

	.copyright .socials {
		float: none;
		margin: 0 auto;
		text-align: center;
		display: inline-block;
	}

	.right-flag {
		float: none;
		display: inline-block;
		margin: 0 auto;
	}

	.left-works-box h2 {
		font-size: 26px;
	}

	.left-works-box p {
		font-size: 14px;
	}

	.how-text-left {
		width: 100%;
		text-align: center;
		font-size: 70px;
		padding-top: 50px;
	}

	.how-dit-right {
		width: 100%;
		text-align: center;
	}

	.panel-title>a::before {
		right: 16px;
	}

	.dit-img {
		text-align: center;
	}

	.dit-img img {
		margin: 0 auto;
	}

	.how-dit-right h2 {
		font-size: 22px;
	}

	.how-dit-right p {
		padding-right: 0px;
	}

	.line {
		margin: 0 auto;
	}

	.section-how-it-works {
		padding: 40px 0px;
	}

	.works-systems {
		padding: 40px 0px;
	}

	.sys-box-left-a {
		text-align: center;
	}

	.sys-box-left-a p {
		padding-right: 0px;
	}

	.sys-box-right-b {
		text-align: center;
	}

	.sys-box-right-b h2 {
		padding-top: 40px;
	}

	.sys-box-left-c {
		text-align: center;
	}

	.sys-box-left-c h2 {
		padding-top: 40px;
	}

	.section-safety h2 {
		font-size: 20px;
		text-align: center;
	}

	.benenfit-box {
		text-align: center;
		margin-bottom: 35px;
	}

	.faq-main-box .panel-group .panel-title a {
		font-size: 14px;
		padding: 18px 0px;
	}

	.cooming-pc-box {
		padding: 40px 10px;
	}

	.expand h2 {
		font-size: 26px;
	}

	.expand p.lead {
		font-size: 16px;
	}

	.get-box h4 {
		font-size: 22px;
	}

	.main-template-about h1 {
		font-size: 26px;
	}

	.main-template-about p.lead {
		font-size: 16px;
	}

	.does-box .panel-body {
		padding: 50px 45px;
	}

	.account-box .blue-form h2 {
		font-size: 20px;
	}

	.account-box .blue-form .blue-form_question {
		font-size: 16px;
	}

	.section-white h2 {
		font-size: 22px;
	}

	.section-white .img-description {
		margin-bottom: 30px;
	}

	.account-box .blue-form {
		padding: 60px 20px;
	}

	.prod-page-title h2 {
		font-size: 18px;
	}

	.btn-dit-list {
		padding-top: 0px;
	}

	.left-dit-p,
	.right-dit-p {
		float: none;
		width: 100%;
		margin-bottom: 10px;
	}

	.right-dit-p .like-list {
		float: none;
	}

	.spe-a ul li .col-md-4 {
		padding: 0px;
	}

	.description-box h5 {
		border-right: none;
		border-bottom: 1px solid #eeeeee;
	}

	.spe-a ul li .col-md-8 {
		padding: 0px;
	}

	.prod-page .pro-img {
		float: left;
		width: 28%;
	}

	.pof-text {
		float: left;
		width: 65%;
		padding: 10px 11px;
	}

	.prod-page a {
		clear: both;
		display: block;
		margin-top: 5px;
	}

	.dit-p {
		position: relative;
		-webkit-box-shadow: 0 6px 10px -5px rgba(0, 0, 0, 0.1);
		box-shadow: 0 6px 10px -5px rgba(0, 0, 0, 0.1);
	}

	.profile-pro-left {
		margin-top: 0px;
	}

	.profile-left-b ul li a {
		color: #333;
		font-size: 12px;
	}

	.profile-left-b {
		padding: 10px 0px;
	}

	.profile-left-b ul li {
		border-right: 1px solid #333;
		float: left;
	}

	.profile-right-b {
		float: none;
	}

	.profile-b::before {
		display: none;
	}

	.left-profile-box-m .pro-img {
		float: left;
		width: 28%;
	}

	.profile-pro-right .nav-tabs>li>a {
		font-size: 13px;
	}

	.dit-btn a {
		padding: 6px 11px;
	}

	.section-pricing h2 {
		font-size: 24px;
	}

	.section-pricing .lead {
		font-size: 16px;
	}

	.feature {
		margin-bottom: 50px;
	}

	.left-profile-box-m {
		margin-left: 0px;
	}

	.pr {
		padding: 0px 15px;
	}

	.right-nav {
		float: none;
		text-align: center;
		display: grid;
	}

	.login-signup ul li a {
		padding: 10px 38px 10px 10px;
	}

	.login-sr {
		float: none;
		display: flex;
		margin-left: 0;
	}

	.help-box ul li {
		padding-left: 10px;
	}

	.help-box,
	.nav-box {
		border: unset;
		padding: 0;
	}

	.nav-box {
		margin: 0;
	}

	.profile-b img {
		min-height: 160px;
	}

	.copyright {
		text-align: center;
	}

	.right-f-box {
		padding: 0 15px;
	}

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	body {
		font-size: 14px !important;
	}
}


/**----- responsive product slider css -----**/

@media only screen and (min-width: 992px) and (max-width: 1200px) {

	.dit-btn a {
		padding: 6px 6px;
	}

	.carousel_mobile {
		display: none;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
	.carousel_mobile {
		display: none;
	}

	.prod-box {
		margin: 0px 5px;
	}

	.dit-btn a {
		padding: 6px 6px;
	}

}

@media only screen and (min-width: 1280px) and (max-width: 1460px) {
	.carousel_mobile {
		display: none;
	}

	.dit-btn a {
		padding: 6px 8px;
	}

	.prod-box {
		margin: 0px 5px;
	}

}

@media (min-width: 992px) {
	.carousel_mobile {
		display: none;
	}
}

@media (max-width: 991px) {

	.navbar-default .navbar-toggler {
		border: none;
		margin: 0px 0;
		padding: 16px;
	}

	.carousel_mobile {
		display: none;
	}

	#header .navbar .container-fluid .button-28 {
		position: absolute;
		top: 0.9em;
		left: 120px;
	}

	.navbar-default .navbar-toggler {
		border: none;
		margin: 7px 0;
	}

	.navbar-brand {
		margin-right: 0 !important;
		padding: 0;
		border: unset;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 14px !important;
	}

	.carousel_mobile {
		display: block;
	}

	.footer-top h2 {
		color: #000;
		font-size: 1em;
		font-family: 'Raleway', sans-serif;
		font-weight: 300;
		line-height: 26px;
	}

	.form-box {
		position: relative;
		margin-right: 15px;
	}

	.form-box input {
		width: 100%;
		box-sizing: border-box;
		background: #fff;
		color: #646464;
		min-height: 51px;
		border-radius: 2px;
		padding-left: 30px;
		font-size: 1em;
		font-family: 'Raleway', sans-serif;
		padding-right: 140px;
		border-radius: 50px;
	}

	.form-box button {
		border: none;
		background: #1d99ff;
		color: #fff;
		padding: 11px 35px 11px;
		border-radius: 2px;
		font-weight: 600;
		font-size: 1em;
		position: absolute;
		right: 5px;
		top: 4px;
		border-radius: 50px;
	}

	.form-box button:hover {
		background-color: #007dea;
	}

	.help-box-f {
		color: #000;
		/* font-family: 'Courier New', Courier, monospace; */
		font-size: 1em;
		font-weight: 300;
		padding: 4px 0px;

	}

	.help-box-f h4 {
		color: #000;
		/* font-family: 'Raleway', sans-serif; */
		font-size: 1em;
		font-weight: 300;
		padding: 4px 0px;
	}

	.help-box-f p {
		color: #000;
		/* font-family: 'Raleway', sans-serif; */
		font-size: 1em;
		font-weight: 300;
		margin: 0px;
		padding-top: 3px;
	}

	.footer-link-box {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.left-f-box h2 {
		color: #000;
		/* font-family: 'Raleway', sans-serif; */
		font-size: 1em;
		font-weight: 700;
		padding: 0 0 0 10px;
		text-transform: uppercase;
		border-left: solid #007dea 4px;
		margin-bottom: 20px;
		line-height: normal;
	}

	.left-f-box ul {
		margin: 0px;
		padding: 0px;
	}

	.left-f-box ul li {
		padding-bottom: 10px;
	}

	.left-f-box ul li a {
		color: #000;
		/* font-family: 'Raleway', sans-serif; */
		font-size: 1em;
		font-weight: 400;
	}

	.left-f-box ul li a:hover {
		color: #12345b;
	}

	.right-f-box h2 {
		color: #000;
		font-family: 'Raleway', sans-serif;
		font-size: 1em;
		font-weight: 700;
		margin-bottom: 20px;
		text-transform: uppercase;
		padding: 0 0 0 10px;
		border-left: solid #007dea 4px;
	}

	.right-f-box ul {
		margin: 0px;
		padding: 0px;
	}

	.right-f-box ul li {
		padding-bottom: 10px;
	}

	.right-f-box ul li a {
		color: #000;
		font-family: 'Raleway', sans-serif;
		font-size: 1em;
		font-weight: 400;
	}

	.right-f-box ul li a:hover {
		color: #fff;
	}

	/*-- Copyright --*/
	.copyright {
		background: #ffffff;
	}

	.copyright p {
		font-size: 1em;
		color: #000;
	}

	.copyright p img {
		margin-right: 10px;
	}

	.copyright p a {
		color: #000;
	}

	.copyright p a:hover {
		text-decoration: underline;
	}

	.copyright .socials {
		float: right;
		padding: 15px 0px;
	}

	.copyright .socials {
		margin: 0px;
	}

	.copyright .socials li {
		margin: 0px;
	}

	.copyright .socials li a {
		color: #09294c;
		font-size: 18px;
	}

	.right-flag {
		display: inline-block;
		float: right;
		padding: 8px 0px;
		margin-right: 25px;
	}

	#header .right-nav {
		width: 100%;
	}

	#header .right-nav .nav-b {
		width: 100%;
	}

	#header .nav-b .nav-box ul {
		width: 100%;
	}

	.function_ {
		display: block;
		margin-bottom: -1rem;
	}
}

@media (max-width: 576px) {
	body {
		font-size: 16px !important;
	}

	.navbar-default .navbar-toggler {
		border: none;
		margin: 0px 0;
		padding: 10px;
		margin-right: -0.8em;
	}

	.carousel_mobile {
		display: block;
	}

	.detail p {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	#header .nav-b .nav-box ul {
		display: flex;
		flex-direction: column;
	}
}