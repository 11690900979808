#page_home {
    .main-product {
        display: flex;
        align-items: center;
        background-image: url('../../../assets/user/images/banner_top_kafood.png');
        color: #09294c;
        height: 450px;
        width: 100%;
        background-position: bottom;
        background-size: cover;

        @media (max-width: 768px) {
            height: 350px;
        }

        @media (max-width: 576px) {
            height: 250px;
            font-size: 10px;

            .find-box {
                padding-bottom: 10rem !important;
            }

            .left-supplier p {
                margin-left: 40px !important;
            }
        }

        .find-box {
            width: 100%;
            padding-bottom: 15rem;

            .product-sh {
                .form_input_phone {
                    input.form-control {
                        height: 50px;
                        border-radius: 50px;
                        opacity: .8;
                    }

                    button {
                        border-radius: 50px;
                        margin-left: .5rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        opacity: .8;
                    }
                }
            }
        }
    }

    .title_home {
        h1 {
            font-size: 1.5rem !important;

        }
    }

    .cat-main-box {
        .accordion-flush .accordion-item {
            background: unset !important;

            .accordion-button.collapsed {
                background: unset !important;
            }
        }
    }

    .partner {
        padding-top: 1rem;

        .partner-item {
            text-align: center;
            padding-bottom: 2.5rem;

            .partner-img {
                width: 12rem;
                border-radius: 20%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                aspect-ratio: 1 / 1;
                transition: all .3s;
            }

            .partner-img:hover {
                scale: 1.05;
            }
        }


    }
}
#ads_home {
    @media screen and (min-width: 768px) {
        .video-container {
            width: 100%;
            height: 580px; 
        }
        .text-conv{
            height: 400px;
        }
        .img-conv{
            height: 400px;}
            .reverse-conv{
                // flex-direction:  row-reverse;
            }
    }

    @media screen and (max-width: 767px) {
        .video-container {
            width: 100%;
            height: 280px;
        }
        .reverse-conv{
            flex-direction: column-reverse;
        }
        .text-conv{
            height: fit-content;
        }
        .img-conv{
            height: max-content;}
    }
    .textCompany{
        font-weight: bold;
        margin-top: 1rem;
    }
    .text-conv{
        flex-direction: column;
        display: flex;
        justify-content: center; 
       
        align-items: center; 
        h2{
            color: #09294c;
            font-weight: bold;
            font-size: 1.4rem !important;
        }
    }
  .image_top{
    img{
        width: 100%;
    }
  }
  .reverse-conv{

  }
  .img-conv{
    // height: 400px;
    // aspect-ratio: 4/3;
    width: 100%;
    object-fit: contain;
  }
  .form-c{
   padding:5rem;
  
  @media (max-width: 768px) {
    padding:1rem ;


}}
    .title_home {
        h1 {
            font-size: 1.75rem !important;
color: #00366f;
        }
    }

    .cat-main-box {
        .accordion-flush .accordion-item {
            background: unset !important;

            .accordion-button.collapsed {
                background: unset !important;
            }
        }
    }

    .partner {
        padding-top: 1rem;

        .partner-item {
            text-align: center;
            padding-bottom: 2.5rem;

            .partner-img {
                width: 12rem;
                border-radius: 20%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                aspect-ratio: 1 / 1;
                transition: all .3s;
            }

            .partner-img:hover {
                scale: 1.05;
            }
        }


    }
}

@keyframes blink {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
.text-gradient{
    font-size: 1.34rem;
    font-weight: bold;
    color: #09294c;
    // background: linear-gradient(45deg, #ff00ff, #00ffff);
    // color: transparent;
    // background-clip: text;
    // -webkit-background-clip: text;
    // animation: blink 2s infinite alternate;
}
.form-contact{
    // background: red !important;
    border:#badefc 2px solid !important
}