#page_blog {
    .container {
        .row {
            h2.blog-topic-name {
                border-bottom: 1px solid #d2d2d2;
            }

            .card {
                margin-bottom: 1.5rem;
                height: 20rem;
                background-color: #fff;

                .card-body {
                    position: absolute;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;

                    .card-title {
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .card-title:hover {
                        color: #0C63E4;
                    }

                    .card-text {
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .cart-reviews {
                        height: 2rem;
                        line-height: 2rem;
                    }
                }
            }

            .col-md-3 .card {

                img {
                    height: 50%;
                    object-fit: contain;
                }

                .card-body {
                    background-color: #fff;
                    top: 50%;
                    height: 50%;

                    .card-title {
                        -webkit-line-clamp: 2;

                        a {
                            color: inherit;
                        }
                    }

                    .card-text {
                        -webkit-line-clamp: 2;
                    }

                    .cart-reviews {
                        svg {
                            color: #212529;
                        }
                    }
                }
            }

            .col-md-6 .card {

                img {
                    height: 100%;
                    object-fit: contain;
                }

                .card-body {
                    color: #fff;
                    background-image: linear-gradient(rgba(245, 245, 245, 0.1), rgba(0, 0, 0, 0.8));
                    top: 40%;
                    height: 60%;
                    padding-top: 8%;

                    .card-title {
                        -webkit-line-clamp: 1;

                        a {
                            color: inherit;
                        }
                    }



                    .card-text {
                        -webkit-line-clamp: 3;
                    }



                    .cart-reviews {
                        svg {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}