.image-container {
  img {
    width: 100%;
    height: 250px;
    padding: 1rem;
    object-fit: contain;
    aspect-ratio: 1/1;
  }
}

.container {
  .subtitle {
    font-size: 1.25rem;
    text-align: center;
    margin: 1rem 0 1rem 0rem;
  }
}
