/* Đảm bảo ảnh được hiển thị đẹp và giữ nguyên tỷ lệ */
.img-fluid {
  width: 100%;
  height: auto;
}

/* Tùy chỉnh hình ảnh trong Col */
.about-img {
  max-width: 100%;
  height: auto;
  object-fit: fill; /* Giúp hình ảnh giữ tỷ lệ mà không bị méo */
  border-radius: 8px; /* Tạo bo góc nhẹ cho hình ảnh */
}

/* Tùy chỉnh cho các kích thước màn hình khác nhau */
@media (max-width: 768px) {
  .about-img {
    margin-bottom: 15px;
  }
}
.about-row {
  display: flex;
  align-items: stretch;
}

.about-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-img {
  object-fit: cover;
  /* height: 100%; */
  width: 100%;
}
@media (max-width: 768px) {
  .function-ul{
    margin-left: unset !important;}
}
.function-ul{
  margin-left: 35px;
  list-style: disc;
  list-style: disc;
    line-height: 3rem;
    text-align: justify;
    font-size: 1.5rem;
    font-weight: 520;
}

.funtion-title{
  color: blue;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 20px
}
.bnt-xct{
  margin-left: 2.5rem;
}