#header {

    .navbar {

        .container-fluid {


            .collapse {

                .right-nav {

                    .nav-b {

                        .nav-box {


                            ul {


                                li {


                                    a.active {
                                        color: #1e96ff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}